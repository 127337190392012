import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "pw-form-container" }
const _hoisted_2 = { class: "pw-form-content" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "ar-field" }
const _hoisted_5 = { class: "formlabel" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_8 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_9 = { class: "switch-form" }
const _hoisted_10 = {
  key: 0,
  class: "form-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedHeaderTop = _resolveComponent("FixedHeaderTop")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FixedHeaderTop, { maxWidth: 48 }),
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('pw_forgotten')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('form.username')), 1),
            _withDirectives(_createElementVNode("input", {
              name: "email",
              type: "email",
              placeholder: _ctx.$t('form.username'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
              autocomplete: "off"
            }, null, 8, _hoisted_6), [
              [_vModelText, _ctx.form.username]
            ]),
            (_ctx.errorClass('email', _ctx.form.username)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('email', _ctx.form.username)=='wrongMail')
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.wrongMail')), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Button, {
            text: _ctx.$t('button.sendLink'),
            waitForResponse: _ctx.waitForResponse,
            onHandleClicked: _ctx.submitForm
          }, null, 8, ["text", "waitForResponse", "onHandleClicked"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, { to: "/loginform" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('button.backToLogin')), 1)
              ]),
              _: 1
            })
          ]),
          (_ctx.linkSent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('link_sent')) + ".", 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}